import React from "react";
import { useTable, useSortBy } from "react-table";
import { Table } from "apache-arrow";
import { Heading } from "@chakra-ui/react";
import style from "./StatTable.module.css";

type StatTableProps = {
  data: Table;
  header: string;
  formatter: {
    ({
      value,
      column,
    }: {
      value: string | number;
      column: { id: string };
    }): string;
  };
};

const StatTable = ({ data, header, formatter }: StatTableProps) => {
  const columns = React.useMemo(() => {
    return data.schema.names.map((x) => {
      return {
        Header: String(x).toUpperCase(),
        accessor: String(x),
        Cell: formatter,
        sortType: "basic",
      };
    });
  }, [data, formatter]);

  // Ideally we could have react-table apply these formatters
  const rowData = React.useMemo(() => {
    return data.toArray();
  }, [data]);
  const tableInstance = useTable({ columns, data: rowData }, useSortBy);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <React.Fragment>
      <Heading size="lg">{header}</Heading>
      <table className={style.tbl} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th className={style.th} {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {}
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td className={style.td} {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default StatTable;
