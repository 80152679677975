import { Dispatch, SetStateAction } from "react";
import { Button, ButtonGroup } from "@chakra-ui/react";
import styles from "./WeekFilters.module.css";

type WeekFilterProps = {
  weeks: number[];
  setWeek: Dispatch<SetStateAction<number>>;
  selectedWeek: number;
};

const WeekFilters = ({ weeks, setWeek, selectedWeek }: WeekFilterProps) => {
  const buttons = weeks.slice(0).map((week) => {
    return (
      <Button
        colorScheme={week === selectedWeek ? "green" : "gray"}
        className={styles.btn}
        key={`${week}`}
        id={`${week}`}
        onClick={() => setWeek(week)}
      >
        Week {week}
      </Button>
    );
  });
  return (
    <ButtonGroup display={"flex"} flexWrap={"wrap"}>
      {buttons}
    </ButtonGroup>
  );
};

export default WeekFilters;
