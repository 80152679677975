import { Button, ButtonGroup } from "@chakra-ui/react";
import styles from "./YearFilters.module.css";

type YearFilterProps = {
  years: number[];
  setYear: (year: number) => void;
  selectedYear: number;
};

const YearFilters = ({ years, setYear, selectedYear }: YearFilterProps) => {
  const buttons = years
    .slice(0)
    .reverse()
    .map((year) => {
      return (
        <Button
          colorScheme={year === selectedYear ? "green" : "gray"}
          className={styles.btn}
          key={`${year}`}
          id={`${year}`}
          onClick={() => setYear(year)}
        >
          Year {year}
        </Button>
      );
    });
  return (
    <ButtonGroup display={"flex"} flexWrap={"wrap"}>
      {buttons}
    </ButtonGroup>
  );
};

export default YearFilters;
