import React, { useRef, useEffect } from "react";
import LineChart from "./LineChart";
import { Table } from "apache-arrow";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { useColorModeValue, useTheme } from "@chakra-ui/react";

type RankingType = {
  team: string;
  week: number;
  rank: number;
};

const RankHistoryChart = ({ rankHistory }: { rankHistory: Table[] }) => {
  const headerRef = useRef<HTMLElement>(null);
  const { height, width } = useWindowDimensions();

  const flattened = rankHistory
    .map((tbl, index) => {
      return tbl.toArray().map((row) => {
        return { week: index + 1, team: row["team"], rank: row["total"] };
      });
    })
    .reduce((acc, curr) => acc.concat(curr));

  const theme = useTheme();
  const lineColor = useColorModeValue(theme.colors.green["500"], theme.colors.green["200"]);

  // upper bound is arbitrarily chosen to fit the perceived min/max
  const yMax = 60;
  const yMin = 10;
                              
  // https://github.com/observablehq/plot-create-react-app-example
  useEffect(() => {
    const chart = LineChart(flattened, {
      x: (d: RankingType) => d.week,
      y: (d: RankingType) => d.rank,
      z: (d: RankingType) => d.team,
      yLabel: "Weekly Rank",
      height: height / 3,
      width: width,
      color: lineColor,
      title: undefined,
      defined: undefined,
      xDomain: [1, Object.keys(rankHistory).length],
      yDomain: [yMin, yMax],
      yFormat: undefined,
      zDomain: undefined,
      strokeLinecap: undefined,
      strokeLinejoin: undefined,
      strokeOpacity: undefined,
      voronoi: undefined,
    });

    headerRef?.current?.append(chart);
    return () => chart.remove();
  }, [flattened, rankHistory, height, width, lineColor]);

  return (
    <div>
      <header ref={headerRef} />
    </div>
  );
};

export default RankHistoryChart;
